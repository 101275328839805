import zoid from 'zoid'

class FrameFactory {
  constructor () {
    this.frameComponents = {}
  }

  hashCode (s) {
    return Math.abs(s.split('').reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0))
  }

  getFrame (variant = 'embed', url = null, autoRender = false) {
    const provenanceUrl = (window.provenance && window.provenance.rootUrl) || 'https://www.provenance.org'
    url = url || `${provenanceUrl}/x`

    const key = `${variant}-${this.hashCode(url)}`

    console.debug('SDK:FrameFactory', variant, url, key)

    if (this.frameComponents[key]) {
      return this.frameComponents[key]
    }

    let height = '100%'
    let autoResizeHeight = true
    const width = '100%'

    switch (variant) {
      case 'small':
        height = '524px'
        break
      case 'story':
        break
      case 'full':
        autoResizeHeight = false
        break
    }

    const component = zoid.create({
      tag: `provenance-experience-${key}`,
      url,
      dimensions: {
        height: height,
        width: width
      },
      attributes: {
        iframe: {
          csp: this.csp(provenanceUrl),
          sandbox: 'allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox'
        }
      },
      autoResize: {
        height: autoResizeHeight,
        width: false
      },
      props: {
        url: {
          type: 'string',
          required: true
        },
        version: {
          type: 'string',
          required: false
        },
        openModal: {
          type: 'function',
          required: false
        }
      },
      autoRender,
      prerenderTemplate: function containerTemplate ({ doc }) {
        // Returning null makes sure the default zoid loading spinner
        // doesn't display.
        return null
      },
      // The slightly odd setup for exports is necessary so that we wait until the component is finishing rendering
      // See https://github.com/krakenjs/zoid/blob/master/docs/api/xprops.md#xpropsexport--string--any---promisevoid
      exports: ({ getExports }) => {
        return {
          onParentEvent: (event) => getExports().then(exports => exports.onParentEvent(event))
        }
      }
    })

    this.frameComponents[key] = {
      component: component,
      class: `ProvenanceModal--${variant}Variant`
    }
    return this.frameComponents[key]
  }

  csp (provenanceUrl) {
    let connectSrc = `'self' https: ${provenanceUrl} https://upload.filestackapi.com https://appsignal-endpoint.net`
    let frameAncestorsHttp = ''

    if (provenanceUrl === 'https://www.provenance.org') {
      provenanceUrl = 'https://assets.provenance.org'
    } else if (provenanceUrl === 'https://staging.provenance.org') {
      provenanceUrl = 'https://assets-staging.provenance.org'
    } else {
      connectSrc += ' http://provenance.test http://provenance.test:3000 http://localhost:8545 http://localhost:5001 https://ipfs.infura.io:5001 https://ipfs-cluster.provenance.org https://goerli.infura.io https://mainnet.infura.io wss://localhost:3035 https://localhost:3035'
      frameAncestorsHttp = 'http:'
    }

    let csp = ''
    csp += "base-uri 'none';"
    csp += "frame-src 'self' https:;"
    csp += `frame-ancestors 'self' https: ${frameAncestorsHttp};`
    csp += `font-src 'self' ${provenanceUrl};`
    csp += `img-src 'self' https: data: ${provenanceUrl};`
    csp += `manifest-src 'self' ${provenanceUrl};`
    csp += `media-src 'self' https: ${provenanceUrl};`
    csp += "object-src 'none';"
    csp += "child-src 'none';"
    csp += "worker-src 'none';"
    csp += `connect-src ${connectSrc}`

    return csp
  }
}

export const frameFactory = new FrameFactory()
