import { track as gtmTrack } from './adapters/google_tag_manager.js'
import { track as segmentTrack } from './adapters/segment.js'
import { track as uaTrack } from './adapters/universal_analytics.js'

// Track analytics. Default to sending to both Provenance and client analytics
export function track (eventData, roles = ['provenance', 'client']) {
  if (window.provenance && window.provenance.disableAnalytics) {
    console.debug('Provenance: Analytics tracking call ignored')
    return
  }

  roles.forEach((role) => {
    switch (role) {
      case 'client': gtmTrack(eventData.ga); break
      case 'provenance': uaTrack(eventData.ga); break
    }
  })
  if (eventData.segment) {
    segmentTrack(eventData.segment)
  }
}

export function listenForImpressions (container, detectedCallback) {
  const observer = createObserver(detectedCallback)

  observer.observe(container)
}

function createObserver (detectedCallback) {
  const options = {
    threshold: 0.5
  }

  return new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!observer) {
          console.log('Observer is undefined or null')
        } else {
          observer.disconnect()
        }
        detectedCallback()
      }
    })
  }, options)
}
