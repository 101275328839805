import { listenForImpressions, track } from '../analytics.js'

const supportedLanguages = ['de', 'en', 'es', 'fr', 'nl']

export async function ProvenanceTrustBadge (containerElement, offersResponse) {
  const { proofPoints, trustBadge: { variant: name, backgroundColor: bg }, bundle: { autoRenderBundle } } = await offersResponse.json()

  const variant = containerElement.getAttribute('variant') || name
  const style = await getStyle(variant)
  const html = await getHTML(variant)
  const anchorRuleSet = bg ? `provenance-trust-badge a { background: ${bg} } #prov-icons > * { box-shadow: 0 0 0 2px ${bg} }` : ''

  containerElement.innerHTML = `<style>${style.default}${anchorRuleSet}</style>${html.default}`
  containerElement.querySelector('a').setAttribute('aria-label', 'Go to verified product transparency information')

  if (/banner|proofpoints|v2/.test(variant)) {
    addProofPoints(containerElement, proofPoints)
  }

  containerElement.addEventListener('click', handleClick)
  setupAnalytics(containerElement, variant)

  return autoRenderBundle
}

function handleClick () {
  const frame = document.querySelector('provenance-bundle iframe')
  frame && frame.focus()
}

export function setupAnalytics (element, variant = 'v1') {
  const eventAction = `proofofimpact_${variant}`
  const eventLabel = window.location.hostname

  listenForImpressions(element, () => {
    track({
      ga: {
        event: 'provenance:impression_badge_trust',
        eventCategory: 'provenance:impression_badge_trust',
        eventAction,
        eventLabel
      },
      segment: {
        object: 'trustBadge',
        action: 'impression',
        url: eventLabel,
        badge_type: eventAction
      }
    })
  })

  element.addEventListener('click', () => {
    track({
      ga: {
        event: 'provenance:click_badge_trust',
        eventCategory: 'provenance:click_badge_trust',
        eventAction,
        eventLabel
      },
      segment: {
        object: 'trustBadge',
        action: 'clicked',
        url: eventLabel,
        badgeType: eventAction
      }
    })
  })
}

function getStyle (variant) {
  switch (variant) {
    case 'light':
      return ''
    case 'v2':
      return import('./v2.css')
    case 'tick_dark':
      return import('./badge_tick-dark.css')
    case 'tick':
      return import('./badge_tick-light.css')
    case 'proofpoints_dark':
      return import('./badge_proofpoints-dark.css')
    case 'proofpoints':
      return import('./badge_proofpoints-light.css')
    case 'banner_dark':
      return import('./banner-dark.css')
    case 'banner':
      return import('./banner-light.css')
    default:
      return import('./v1.css')
  }
}

function getHTML (variant) {
  // Get the preferred language of the user from the browser. The .slice(0, 2)
  // call is needed because, on the server side, we support ISO 639-1
  // two-letter codes, but `navigator.language` returns subtags as well, such
  // as "en_US" or "en_GB" (these are known as IETF BCP 47 language tags).
  let lang = navigator.language.slice(0, 2)

  if (!supportedLanguages.includes(lang)) {
    lang = 'en'
  }

  // For Skandinavisk, get the page language instead
  if (location.hostname === 'www.skandinavisk.com') {
    lang = document.documentElement.getAttribute('lang')
  }

  switch (variant) {
    case 'light':
      return import('./light.html')
    case 'v2':
      return import('./v2.html')
    case 'tick_dark':
      return import(`./badge_tick-dark.${lang}.html`)
    case 'tick':
      return import(`./badge_tick-light.${lang}.html`)
    case 'proofpoints_dark':
      return import(`./badge_proofpoints-dark.${lang}.html`)
    case 'proofpoints':
      return import(`./badge_proofpoints-light.${lang}.html`)
    case 'banner_dark':
      return import(`./banner-dark.${lang}.html`)
    case 'banner':
      return import(`./banner-light.${lang}.html`)
    default:
      return import('./v1.html')
  }
}

function addProofPoints (el, proofPoints) {
  const icons = document.getElementById('prov-icons')

  if (proofPoints.length > 3) {
    // Insert first two icons
    icons.insertAdjacentHTML('afterbegin', proofPoints[1].iconHTML)
    icons.insertAdjacentHTML('afterbegin', proofPoints[0].iconHTML)

    // Insert counter of hidden PPs
    const div = document.createElement('div')
    div.innerText = `+${proofPoints.length - 2}`
    icons.insertAdjacentElement('beforeend', div)
  } else {
    // Insert all icons
    proofPoints.reverse().forEach(pp => {
      icons.insertAdjacentHTML('afterbegin', pp.iconHTML)
    })
  }
}
