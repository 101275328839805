function get (options) {
  if (!options.id) {
    console.log('Provenance: no identifier found in page')
    return Promise.reject(new Error('Missing identifier'))
  }
  const apiOrigin = options.apiOrigin || 'https://api.provenance.org'

  let url = `${apiOrigin}/v1/${options.endpoint}`

  if (options.id) {
    url += `/${encodeURIComponent(options.id)}`
  }
  if (options.type) {
    url += `?type=${options.type}`
  }

  return fetch(url, {
    method: 'get',
    headers: {
      ...(options.accessToken && { 'X-API-KEY': options.accessToken }),
      ...(options.shopifyShopId && { 'X-SHOPIFY-SHOP-ID': options.shopifyShopId })
    }
  })
}

/**
 * API call that serves two purposes:
 * 1. verify that the accessToken is valid, and do appropriate server-side checks to see if
 *    the Trust Badge should be rendered;
 * 2. returns the Proof Point icons that are currently only used in a few variants;
 * 3. returns the Trust Badge variant name that should be rendered.
 * @returns Promise
 */
export function provenanceContent ({ accessToken, apiOrigin, identifier, shopifyShopId }) {
  return get({
    accessToken,
    apiOrigin,
    endpoint: 'offers',
    id: identifier.identifier,
    shopifyShopId,
    type: identifier.type
  })
}
