import { addVariantListener } from './bundle.js'

const BRAND_SCHEMA_SELECTOR = '[itemtype$="://schema.org/Brand"]'
const PRODUCT_SCHEMA_SELECTOR = '[itemtype$="://schema.org/Product"]'

const BRAND_IDENTIFIERS = ['identifier']
const PRODUCT_IDENTIFIERS = ['sku', 'gtin', 'gtin12', 'gtin13', 'gtin14', 'gtin8', 'mpn', 'productID']

const MAX_RETRIES = 15
const INITIAL_DELAY_TIME = 350

export async function findBundleContentsId (schemaType) {
  addVariantListener()
  return withRetries(() => bundleContentsId(schemaType))()
}

export async function findProductIdentifier () {
  const productIdentifierPromise = await withRetries(productIdentifier)()
  return productIdentifierPromise
}

// Modified from: https://stackoverflow.com/a/55270741/1582976
const withRetries = (fn) => async (...args) => {
  let retryCount = 0

  while (retryCount <= MAX_RETRIES) {
    const result = await fn(...args)

    if (result) return result

    await new Promise((resolve) => setTimeout(resolve, INITIAL_DELAY_TIME))
    retryCount++
  }

  return null
}

function bundleContentsId (schemaType) {
  switch (schemaType) {
    case 'Brand':
      return brandIdentifier()
    case 'Product':
      return productIdentifier()
    default:
      return productIdentifier() || brandIdentifier()
  }
}

export function encodeBundleContentsId (schema, id) {
  return `${schema}/${encodeURIComponent(id)}`
}

function brandIdentifier () {
  return getIdentifierFromHtml(BRAND_SCHEMA_SELECTOR, BRAND_IDENTIFIERS, 'user') || brandIdFromJsonLd()
}

export function productIdentifier () {
  return getIdentifierFromHtml(PRODUCT_SCHEMA_SELECTOR, PRODUCT_IDENTIFIERS, 'product') ||
  productIdFromJsonLd() || productIdFromShopifyMetadata()
}

function getIdentifierFromHtml (selector, identifiers, schemaType) {
  // First, try to find the exact match for the schema type
  const el = document.querySelector(selector)
  let id = findIdFromSchema(el, identifiers)
  if (id) return { ...id, schema: schemaType }

  // If no exact match, try a more flexible search across the document
  id = findIdFromSchema(document, identifiers)
  return id ? { ...id, schema: schemaType } : null
}

function findIdFromSchema (schema, fields) {
  if (!schema) return null
  for (const field of fields) {
    const el = schema.querySelector(`[itemprop~=${field}]`)
    if (el) return { identifier: el.textContent || el.content, type: field }
  }
  return null
}

function productIdFromShopifyMetadata () {
  const variants = window.ShopifyAnalytics?.meta?.product?.variants
  if (!variants) return null

  const urlVariantId = new URLSearchParams(location.search).get('variant')
  const sku = urlVariantId
    ? variants.find((v) => v.id.toString() === urlVariantId)?.sku
    : variants[0]?.sku

  return sku ? { schema: 'product', identifier: sku, type: 'sku' } : null
}

function brandIdFromJsonLd () {
  for (const obj of jsonLdObjects()) {
    if (obj['@type'] === 'Brand') {
      const key = BRAND_IDENTIFIERS.find((key) => obj[key])
      if (key) return { schema: 'user', identifier: obj[key], type: key }
    }
  }
  return null
}

function productIdFromJsonLd () {
  for (let obj of jsonLdObjects()) {
    if (Array.isArray(obj) && obj.length === 1) {
      obj = obj[0]
    }
    if (Array.isArray(obj['@graph'])) {
      const product = obj['@graph'].find((o) => o['@type'] === 'Product')
      if (product) {
        const key = PRODUCT_IDENTIFIERS.find((key) => product[key])
        if (key) return { schema: 'product', identifier: product[key], type: key }
      }
    }
    if (obj['@type'] === 'ProductGroup') {
      const product = obj.hasVariant
      if (product && product[0]) {
        const key = PRODUCT_IDENTIFIERS.find((key) => product[0][key])
        if (key) return { schema: 'product', identifier: product[0][key], type: key }
      }
    }
    if (obj['@type'] === 'Product') {
      const key = PRODUCT_IDENTIFIERS.find((key) => obj[key])
      if (key) return { schema: 'product', identifier: obj[key], type: key }
    }
  }
  return null
}

/**
 * Checks for one or more `<script type="application/ld+json">` in the page.
 * @returns {Array} of parsed JSON-LD objects
 */
export function jsonLdObjects () {
  return Array.from(document.querySelectorAll('[type="application/ld+json"]')).flatMap((script) => {
    try {
      return [JSON.parse(script.textContent)]
    } catch (e) {
      return []
    }
  })
}
